import { gql } from 'graphql-request'

export const BULK_UPDATE_CHARACTERS = gql`
  mutation BulkUpdateCharacters(
    $bulkUpdateCharactersInput: BulkUpdateCharactersInput!
  ) {
    bulkUpdateCharacters(
      bulkUpdateCharactersInput: $bulkUpdateCharactersInput
    ) {
      id
      characterRole {
        id
      }
      imgPath
      resizeImgPath
      name
      description
      visible
      donatable
      publishedAt
    }
  }
`
