import { plainToInstance } from 'class-transformer'
import { DateTime } from 'luxon'

import {
  UpdateCharacterDonateType,
  UpdateCharacterPublishStatusType,
} from '@client/collections/Character/interface'
import { BULK_REMOVE_CHARACTER } from '@client/collections/Character/schemas/bulkRemoveCharacter'
import { BULK_UPDATE_CHARACTERS } from '@client/collections/Character/schemas/bulkUpdateCharacters'
import { CREATE_CHARACTER_DONATION } from '@client/collections/Character/schemas/createCharacterDonation'
import { CREATE_CHARACTERS } from '@client/collections/Character/schemas/createCharacters'
import { DAILY_TOPCHART_CHARACTER } from '@client/collections/Character/schemas/dailyTopChartCharacter'
import { GET_ALL_CHARACTERS } from '@client/collections/Character/schemas/getAllCharacters'
import { GET_CHARACTER_BY_ID } from '@client/collections/Character/schemas/getCharacterById'
import { GET_CHARACTER_ROLES } from '@client/collections/Character/schemas/getCharacterRole'
import { GET_TOP_CHART_CHARACTERS } from '@client/collections/Character/schemas/getCharacterTopCharts'
import { GET_MONTHS } from '@client/collections/Character/schemas/getMonths'
import { UPDATE_CHARACTER_STATUS } from '@client/collections/Character/schemas/updateCharacter'
import { UPDATE_CHARACTERS } from '@client/collections/Character/schemas/updateCharacters'
import { UPLOAD_CHARACTER_PHOTO } from '@client/collections/Character/schemas/uploadCharacterPhoto'
import { BookEnum } from '@interfaces/BookEnum'
import { CharacterSearchParamsType } from '@interfaces/CharacterSearchParamsType'
import { RecommendTypeSearchEnum } from '@interfaces/RecommendTypeSearchEnum'
import { TopChartEnum } from '@interfaces/TopChartEnum'
import { clearComma } from '@lib/utils'
import { BookCharacterType } from '@models/book/BookCharacterType'
import { AllCharactersResponse } from '@models/character/AllCharactersResponse'
import { CharacterRoleOptionType } from '@models/character/CharacterRoleOptionType'
import { CreateCharacterDonationResponse } from '@models/character/CreateCharacterDonationResponse'
import { DailyTopChartCharacterType } from '@models/character/DailyTopChartCharacterType'
import { TopChartCharacterType } from '@models/character/TopChartCharacterType'
import { CharacterType } from '@models/myWriting/CharacterType'
import { CharacterMetaDataType } from '@models/share/CharacterMetaDataType'
import { gqlApiInstance } from '@client/init'
import { Option } from '@components/TopChartSection/OptionSelect/interface'
import { ResizeTypeEnum } from '@interfaces/ResizeTypeEnum'
import { UserCoin } from '@models/user/UserCoin'
import { event, ActionEnum } from '@lib/gtag'
import { DONATE_CHARACTER_CONVERSION } from '@configs/config'
import { SupportCharacterFormType } from '@components/SupportCharacterModal/interface'
import { fbqDonateCharacter } from '@lib/facebookPixel'

interface UpdateCharacterStatus {
  visible?: boolean
  donatable?: boolean
  publishedAt?: string | null
}

export function useCharacterAction() {
  async function uploadCharacterPhoto(
    file: File | Blob
  ): Promise<{ filePath: string; filePathResize?: string }> {
    const { UploadBookCover } = await gqlApiInstance.request(
      UPLOAD_CHARACTER_PHOTO,
      {
        file,
        resizeType: ResizeTypeEnum.CHARACTER,
      }
    )

    return UploadBookCover
  }

  async function getCharacterRoles(): Promise<CharacterRoleOptionType[]> {
    const { characterRoles } = await gqlApiInstance.request(GET_CHARACTER_ROLES)

    return characterRoles.map((role: any) =>
      plainToInstance(CharacterRoleOptionType, role)
    )
  }

  async function getTopChartCharacters({
    topChartsType,
    endDate,
    limit = 3,
  }: {
    topChartsType: TopChartEnum
    startDate: DateTime
    endDate: DateTime
    limit?: number
  }): Promise<TopChartCharacterType[]> {
    const { getCharacterTopCharts } = await gqlApiInstance.request(
      GET_TOP_CHART_CHARACTERS,
      {
        topChartsType,
        limit,
        startDate: endDate.startOf('day').toISO({ includeOffset: false }),
        endDate: endDate
          .plus({ day: 1 })
          .endOf('day')
          .toISO({ includeOffset: false }),
      }
    )

    return plainToInstance(TopChartCharacterType, <any[]>getCharacterTopCharts)
  }

  async function createCharacters(
    data: CharacterType[],
    bookId: number
  ): Promise<CharacterType[]> {
    const uploadRequests = data.map(row =>
      uploadCharacterPhoto(row.imgPath!.blob!)
    )

    const images = await Promise.all(uploadRequests)
    const characters = data.map((row, index) => ({
      imgPath: images[index].filePath,
      resizeImgPath: images[index].filePathResize,
      name: row.name,
      characterRoleId: row.characterRoleId,
      description: row.description,
      donatable: row.isDonate,
      visible: row.isPublish,
      publishedAt: row.isPublish ? DateTime.now().toUTC() : null,
    }))

    const response = await gqlApiInstance.request(CREATE_CHARACTERS, {
      createCharactersInput: {
        bookId,
        characters,
      },
    })

    return plainToInstance(CharacterType, response.createCharacters as [])
  }

  async function updateCharacterStatus(
    id: number,
    form: UpdateCharacterStatus
  ): Promise<BookCharacterType> {
    const { updateCharacter } = await gqlApiInstance.request(
      UPDATE_CHARACTER_STATUS,
      {
        updateCharacterInput: { id, ...form },
      }
    )

    return plainToInstance(BookCharacterType, updateCharacter)
  }

  async function bulkRemoveCharacter(ids: number[]): Promise<CharacterType> {
    const response = await gqlApiInstance.request(BULK_REMOVE_CHARACTER, {
      ids,
    })

    return response.bulkRemoveCharacter
  }

  async function createCharacterDonation(
    characterId: number,
    userCoin: UserCoin,
    silverCoin: string,
    goldCoin: string,
    thirdCoin?: string
  ): Promise<CreateCharacterDonationResponse> {
    const coins = []
    const gold = clearComma(goldCoin)
    const silver = clearComma(silverCoin)
    const third = thirdCoin ? clearComma(thirdCoin) : 0

    if (gold) {
      coins.push({
        coinId: userCoin.paidCoin?.id,
        coinValue: gold,
        coinType: userCoin.paidCoin?.type,
      })
    }

    if (silver) {
      coins.push({
        coinId: userCoin.freeCoin?.id,
        coinValue: silver,
        coinType: userCoin.freeCoin?.type,
      })
    }

    if (third) {
      coins.push({
        coinId: userCoin.thirdCoin?.id,
        coinValue: third,
        coinType: userCoin.thirdCoin?.type,
      })
    }

    const response = await gqlApiInstance.request(CREATE_CHARACTER_DONATION, {
      createCharacterDonationInput: {
        characterId,
        coins,
      },
    })

    return plainToInstance(CreateCharacterDonationResponse, {
      characterDonation:
        response.createCharacterDonation.characterDonation.character,
      transactionId: response.createCharacterDonation.transactionId,
    })
  }

  async function getAllCharacters({
    categoryIds,
    characterRoleId,
    bookTypes,
    chapterType,
    ...params
  }: CharacterSearchParamsType): Promise<AllCharactersResponse> {
    const response = await gqlApiInstance.request(GET_ALL_CHARACTERS, {
      ...params,
      categoryIds: categoryIds.length === 0 ? null : categoryIds.map(Number),
      characterRoleIds: characterRoleId ? [characterRoleId] : null,
      bookType:
        bookTypes.length === 0 ||
        [BookEnum.MANGA, BookEnum.NOVEL].every(value =>
          bookTypes.includes(value)
        )
          ? null
          : bookTypes[0],
      chapterType:
        chapterType &&
        [
          RecommendTypeSearchEnum.WHOLE,
          RecommendTypeSearchEnum.CHAPTER,
        ].includes(chapterType)
          ? chapterType
          : null,
      isEnd:
        chapterType === RecommendTypeSearchEnum.ENDED
          ? true
          : chapterType === RecommendTypeSearchEnum.NOT_END
          ? false
          : null,
    })

    return plainToInstance(AllCharactersResponse, response.getAllCharacters)
  }

  async function updateCharactersDonate({
    bookId,
    characterIds,
    updateType,
  }: UpdateCharacterDonateType) {
    await gqlApiInstance.request(UPDATE_CHARACTERS, {
      updateCharactersInput: {
        updateType,
        bookId,
        characterIds,
      },
    })
  }

  async function updateCharactersPublishStatus({
    bookId,
    updateType,
    characterIds,
    publishedAt,
  }: UpdateCharacterPublishStatusType) {
    await gqlApiInstance.request(UPDATE_CHARACTERS, {
      updateCharactersInput: {
        updateType,
        bookId,
        characterIds,
        publishedAt,
      },
    })
  }

  async function getCharacterById(id: number): Promise<CharacterMetaDataType> {
    const response = await gqlApiInstance.request(GET_CHARACTER_BY_ID, {
      getCharacterById: id,
    })

    return plainToInstance(CharacterMetaDataType, response.getCharacterById)
  }

  async function bulkUpdateCharacters(
    data: CharacterType[],
    bookId: number
  ): Promise<CharacterType[]> {
    const formData = []
    // eslint-disable-next-line no-restricted-syntax
    for (const row of data) {
      let imgPath = ''
      let resizeImgPath

      if (row?.imgPath?.blob) {
        // eslint-disable-next-line no-await-in-loop
        const uploadResp = await uploadCharacterPhoto(row.imgPath.blob)
        imgPath = uploadResp.filePath
        resizeImgPath = uploadResp.filePathResize
      }

      formData.push({
        id: row.id,
        name: row.name,
        characterRoleId: row.characterRoleId,
        description: row.description,
        donatable: row.isDonate,
        visible: row.isPublish,
        publishedAt: row.isPublish
          ? row.publishedAt || DateTime.now().toUTC()
          : null,
        ...(imgPath && { imgPath }),
        ...(imgPath && { resizeImgPath: resizeImgPath || imgPath }),
      })
    }

    const response = await gqlApiInstance.request(BULK_UPDATE_CHARACTERS, {
      bulkUpdateCharactersInput: {
        bookId,
        characters: formData,
      },
    })

    return plainToInstance(CharacterType, response.bulkUpdateCharacters as [])
  }

  async function getMonths(): Promise<Option[]> {
    const { characterChartExistDate } = await gqlApiInstance.request(GET_MONTHS)
    const months = characterChartExistDate.flatMap(
      (row: { existDate: string }) => row.existDate
    )

    return months.map((row: string) => {
      const datetime = DateTime.fromISO(row)

      const value = `${datetime.toFormat('LLLL yyyy')}`
      const label = `${datetime.setLocale('th').toFormat('LLLL')}`
      const subLabel = `${datetime.setLocale('th').toFormat('yyyy')}`

      return {
        value,
        label,
        subLabel,
      }
    })
  }

  async function dailyTopChartCharacter(
    currentDate: Date
  ): Promise<DailyTopChartCharacterType[]> {
    const response = await gqlApiInstance.request(DAILY_TOPCHART_CHARACTER, {
      currentDate,
    })

    return response.dailyTopChartCharacter
  }

  function triggerDonationCharacter(
    form: SupportCharacterFormType,
    transactionId: string
  ) {
    const gold = clearComma(form.paidCoin)
    const silver = clearComma(form.freeCoin)
    const third = form.thirdCoin ? clearComma(form.thirdCoin) : 0
    const value = gold + silver + third

    if (DONATE_CHARACTER_CONVERSION) {
      event(ActionEnum.CONVERSION, {
        send_to: DONATE_CHARACTER_CONVERSION,
        value,
        transaction_id: transactionId,
      })
    }

    fbqDonateCharacter(value)
  }

  return {
    getCharacterRoles,
    getTopChartCharacters,
    createCharacters,
    updateCharacterStatus,
    bulkRemoveCharacter,
    createCharacterDonation,
    getAllCharacters,
    updateCharactersDonate,
    updateCharactersPublishStatus,
    getCharacterById,
    bulkUpdateCharacters,
    getMonths,
    dailyTopChartCharacter,
    triggerDonationCharacter,
  }
}
