import { gql } from 'graphql-request'

export const GET_TOP_CHART_CHARACTERS = gql`
  query GetCharacterTopCharts(
    $topChartsType: CharacterTopChartsType!
    $startDate: DateTime!
    $endDate: DateTime!
    $limit: Int
  ) {
    getCharacterTopCharts(
      topChartsType: $topChartsType
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      bookCharacter {
        id
        name
        imgPath
        resizeImgPath
        description
        donatable
        coinsTotal
        coinsToNextLevel
        visible
        deletedAt
        book {
          id
          title
          status
          deletedAt
        }
        characterRole {
          name
        }
        characterRank {
          id
          name
          startCoins
          endCoins
          imgPath
        }
      }
      chartRank
      characterRank
      characterNextRank
      remainingCoin
      cumulativeGainedCoin
    }
  }
`
