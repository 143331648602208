import { Expose, Type } from 'class-transformer'
import { BookCharacterRankType } from '@models/book/BookCharacterRankType'

class CharacterNextRankType {
  @Expose()
  id: number

  @Expose()
  name: string
}

export class CreateCharacterDonationType {
  @Expose()
  id: number

  @Expose()
  coinsTotal: number

  @Expose()
  coinsToNextLevel: number

  @Type(() => BookCharacterRankType)
  characterRank: BookCharacterRankType

  @Expose({ name: 'nextRank' })
  @Type(() => CharacterNextRankType)
  characterNextRank: CharacterNextRankType
}
