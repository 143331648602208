import { Exclude, Expose, Type } from 'class-transformer'
import { CharacterType } from './CharacterType'

@Exclude()
export class TopChartCharacterType {
  @Expose()
  @Type(() => CharacterType)
  bookCharacter: CharacterType

  @Expose() chartRank: number

  @Expose() characterRank: string

  @Expose() remainingCoin: number

  @Expose() cumulativeGainedCoin: number

  @Expose() characterNextRank: string
}
