import { Expose, Transform } from 'class-transformer'

import { ImageType } from '@interfaces/ImageType'

export class CharacterType {
  @Expose() id?: number

  @Expose() name: string

  @Expose({ name: 'characterRole' })
  @Transform(({ value }) => value?.id, { toClassOnly: true })
  characterRoleId: number

  @Expose() description: string

  @Expose()
  @Transform(({ value }) => ({ url: value }), { toClassOnly: true })
  imgPath: ImageType | undefined

  @Expose({ name: 'visible' })
  @Transform(({ value }) => !!value, { toClassOnly: true })
  isPublish: boolean

  @Expose({ name: 'donatable' })
  @Transform(({ value }) => !!value, { toClassOnly: true })
  isDonate: boolean

  @Expose()
  publishedAt: string

  deletedAt?: string

  @Expose()
  @Transform(() => false)
  isDeleted: boolean

  @Expose()
  @Transform(() => true)
  isEmptyForm: boolean

  @Expose()
  coinsTotal: number
}
