import { gql } from 'graphql-request'

export const DAILY_TOPCHART_CHARACTER = gql`
  query DailyTopChartCharacter($currentDate: DateTime!) {
    dailyTopChartCharacter(currentDate: $currentDate) {
      characterId
      characterImgPath
      characterResizeImgPath
      characterName
      bookId
      bookTitle
    }
  }
`
