import { gql } from 'graphql-request'

export const CREATE_CHARACTER_DONATION = gql`
  mutation CreateCharacterDonation(
    $createCharacterDonationInput: CreateCharacterDonationInput!
  ) {
    createCharacterDonation(
      createCharacterDonationInput: $createCharacterDonationInput
    ) {
      characterDonation {
        character {
          id
          coinsTotal
          coinsToNextLevel
          characterRank {
            id
            name
            startCoins
            endCoins
            imgPath
          }
          nextRank {
            id
            name
          }
        }
      }
      transactionId
    }
  }
`
