import { gql } from 'graphql-request'

export const BULK_REMOVE_CHARACTER = gql`
  mutation BulkRemoveCharacter($ids: [Int!]!) {
    bulkRemoveCharacter(ids: $ids) {
      id
      deletedAt
    }
  }
`
