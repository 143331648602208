import { gql } from 'graphql-request'

export const GET_CHARACTER_BY_ID = gql`
  query GetCharacterById($getCharacterById: Int!) {
    getCharacterById(id: $getCharacterById) {
      id
      name
      description
      facebookShareImageUrl
      twitterShareImageUrl
      visible
      deletedAt
      book {
        id
        title
        status
        isVisible
        deletedAt
      }
    }
  }
`
