import { gql } from 'graphql-request'

export const CREATE_CHARACTERS = gql`
  mutation CreateCharacters($createCharactersInput: CreateCharactersInput!) {
    createCharacters(createCharactersInput: $createCharactersInput) {
      id
      characterRole {
        id
      }
      imgPath
      name
      description
      visible
      publishedAt
    }
  }
`
