import { Expose, Type } from 'class-transformer'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'

class BookType {
  @Expose() id: number

  @Expose() title: string

  @Expose() status: BookStatusEnum

  @Expose() isVisible: boolean

  @Expose() deletedAt: string
}

export class CharacterMetaDataType {
  @Expose() id: number

  @Expose() name: string

  @Expose() facebookShareImageUrl: string

  @Expose() twitterShareImageUrl: string

  @Expose() description: string

  @Expose()
  @Type(() => BookType)
  book: BookType

  @Expose() visible: boolean

  @Expose() deletedAt: string
}
