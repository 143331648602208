/* eslint-disable max-classes-per-file */
import { Expose, Type } from 'class-transformer'
import { BookCharacterRankType } from '@models/book/BookCharacterRankType'

class CharacterRoleType {
  @Expose() name: string
}

class BookType {
  @Expose() id: number

  @Expose() title: string

  @Expose() status: string

  @Expose() deletedAt: string
}

export class CharacterType {
  @Expose() id: number

  @Expose() name: string

  @Expose() imgPath: string

  @Expose() resizeImgPath: string

  @Expose() description: string

  @Expose() coinsTotal: number

  @Expose() coinsToNextLevel: number

  @Expose()
  @Type(() => BookType)
  book: BookType

  @Type(() => CharacterRoleType)
  @Expose()
  characterRole: CharacterRoleType

  @Expose()
  @Type(() => BookCharacterRankType)
  characterRank: BookCharacterRankType

  @Expose({ name: 'nextRank' })
  @Type(() => BookCharacterRankType)
  characterNextRank: BookCharacterRankType

  @Expose() donatable: boolean

  @Expose() visible: boolean

  @Expose() deletedAt: string
}
