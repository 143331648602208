export enum BookEnum {
  MANGA = 'MANGA',
  NOVEL = 'NOVEL',
  EBOOK = 'EBOOK',
}

export const bookTypeValue = {
  [BookEnum.MANGA]: 'การ์ตูน',
  [BookEnum.NOVEL]: 'นิยาย',
  [BookEnum.EBOOK]: 'อีบุ๊ก',
}

export const maximumCreateEbookValue = {
  [BookEnum.MANGA]: 10,
  [BookEnum.NOVEL]: 200,
}
