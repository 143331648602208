import { gql } from 'graphql-request'

export const GET_ALL_CHARACTERS = gql`
  query GetAllCharacters(
    $searchText: String
    $characterOrder: CharacterOrder
    $categoryIds: [Int!]
    $characterRoleIds: [Int!]
    $chapterType: ChapterType
    $bookType: BookType
    $isEnd: Boolean
    $limitPerPage: Int
    $page: Int
  ) {
    getAllCharacters(
      searchText: $searchText
      characterOrder: $characterOrder
      categoryIds: $categoryIds
      characterRoleIds: $characterRoleIds
      chapterType: $chapterType
      bookType: $bookType
      isEnd: $isEnd
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        id
        imgPath
        resizeImgPath
        name
        description
        coinsTotal
        coinsToNextLevel
        donatable
        book {
          id
          title
        }
        characterRole {
          name
        }
        characterRank {
          id
          name
          startCoins
          endCoins
          imgPath
        }
        nextRank {
          id
          name
        }
      }
      page
      total
    }
  }
`
