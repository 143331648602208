import { Expose, Type } from 'class-transformer'

import { CreateCharacterDonationType } from './CreateCharacterDonationType'

export class CreateCharacterDonationResponse {
  @Expose()
  @Type(() => CreateCharacterDonationType)
  characterDonation: CreateCharacterDonationType

  @Expose() transactionId: string
}
