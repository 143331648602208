import { gql } from 'graphql-request'

export const UPLOAD_CHARACTER_PHOTO = gql`
  mutation UploadBookCover($resizeType: ResizeType, $file: Upload!) {
    UploadBookCover(resizeType: $resizeType, file: $file) {
      filePath
      filePathResize
    }
  }
`
