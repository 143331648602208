import { Expose, Type } from 'class-transformer'
import { AllCharacterType } from './AllCharacterTyp'

export class AllCharactersResponse {
  @Expose()
  @Type(() => AllCharacterType)
  data: AllCharacterType[]

  @Expose()
  page: number

  @Expose()
  total: number
}
