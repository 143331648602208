import { gql } from 'graphql-request'

export const GET_CHARACTER_ROLES = gql`
  query characterRoles {
    characterRoles {
      id
      name
      seoTitle
      seoDescription
    }
  }
`

export const GET_CHARACTER_ROLE = gql`
  query characterRole($id: Int!) {
    characterRole(id: $id) {
      id
      name
      seoTitle
      seoDescription
    }
  }
`
