import { Expose, Transform } from 'class-transformer'

export class CharacterRoleOptionType {
  @Expose({ name: 'id' }) value: number

  @Expose({ name: 'name' }) label: string

  @Expose()
  @Transform(({ value }) => value || '')
  seoTitle: string

  @Expose()
  @Transform(({ value }) => value || '')
  seoDescription: string
}
