import { gql } from 'graphql-request'

// TODO: รอย้ายไปใช้ตัว UPDATE_CHARACTER เนื่องจากตัวนั้นมันยังผิดพลาดอยู่
export const UPDATE_CHARACTER_STATUS = gql`
  mutation UpdateCharacter($updateCharacterInput: UpdateCharacterInput!) {
    updateCharacter(updateCharacterInput: $updateCharacterInput) {
      id
      name
      donatable
      visible
      publishedAt
    }
  }
`
